.slick-slider button.slick-arrow.slick-prev {
  color: transparent;
  outline: none;
  background-color: #F9BF29;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 1%;
  height: 60px;
  width: 60px;
  border: none;
  border-radius: 50%;
  background-image: url(../src/app/assets/images/left-arrow.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40%;
}

.slick-slider button.slick-arrow.slick-next {
  color: transparent;
  outline: none;
  background-color: #F9BF29;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 1%;
  height: 60px;
  width: 60px;
  border: none;
  border-radius: 50%;
  background-image: url(../src/app/assets/images/right-arrow.webp);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40%;
}

